:root {
  --azure-x: #EAFDF8;
  --platinum: #E5E9EC;
  --medium-turquoise: #66D7D1;
  
  
  --foreground: #0b132b;
  --secondary: #1c2541;
  --primary: #3a506b;
  --accent: #5bc0be;
  --background: #ffffff;
  
  --success: #81E979;
  --warning: #FF934F;
  --error: #F0544F;
  --error: #E63B2E;
  --muted: #CBD2D0;
  
  --secondary: #8ee3f5;
  --primary: #70cad1;
  /* --foreground: #3e517a; */
  --background-2: #EAFDF8;
  --background: #ffffff;
  /* --accent: #EFBC9B; */
  /* --peachy-crayola: #EFBC9B; */
  /* --accent: #b08ea2; */
  
  --background-3: #a8e0ff;

  /* Another cool pallette */
  /* "fde8e9","e3bac6","bc9ec1","596475","1f2232" */
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
